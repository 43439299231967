import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react'; 
import Header from './components/Header';
import Footer from './components/Footer'; 
import ReactGa from 'react-ga';
import BlogPage from './pages/Blog';
import BlogDetail from './components/BlogDetail';
import About from './pages/About';
import Video from './pages/Video';
import Contact from './pages/Contact';
import SocialSidebar from './components/SocialSidebar';
import ScrollToTopButton from './components/ScrollToTopButton';
import DynamicPage from './components/DynamicPage';
import ContentPage from './components/ContentPage';
import Subscribe from './components/SubscribeForm';
import Fikirpaneli from './pages/FikirPaneli';
import FikirPaneliDetail from './components/FikirPaneliDetail';
import NewsDynamic from './components/NewsDynamic';
import BlogSection from './components/BlogSection';
import CookieConsent from './components/CookieConsent'; 
import CookiePolicy from '../src/pages/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import KvkkPolicy from './pages/KvkkPolicy';
import i18n from './i18n';
function initialGa() {
  ReactGa.initialize('G-FFSW4P8LN1');
}
const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.gaInitialized) {
      initialGa();  // Only initialize GA once
      window.gaInitialized = true;
    }
    ReactGa.pageview(location.pathname + location.search);  // Track page views on route change.
  }, [location]);
  useEffect(() => {
    console.log(i18n);
  }, []);
  useEffect(() => {
    const storedLang = localStorage.getItem('i18nextLng'); // Daha önce seçilen dili kontrol et
    if (!storedLang) {
      // Eğer bir dil seçimi yapılmadıysa, tarayıcı dilini varsayılan yap
      const browserLang = navigator.language.split('-')[0]; // 'en-US' gibi dil kodlarını almak için
      const supportedLangs = ['en', 'fr', 'tr']; // Desteklediğiniz diller
      const defaultLang = supportedLangs.includes(browserLang) ? browserLang : 'tr'; // Varsayılan Türkçe
      i18n.changeLanguage(defaultLang);
    } else {
      i18n.changeLanguage(storedLang); // Daha önce seçilen dili uygula
    }
  }, [i18n]);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:slug" element={<BlogDetail />} />
        <Route path="/about" element={<About />} />
        <Route path="/video" element={<Video />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/:category" element={<DynamicPage />} />
        <Route path="/:category/:slug" element={<ContentPage />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/fikirpaneli" element={<Fikirpaneli />} />
        <Route path="/fikirpanelidetail/:id" element={<FikirPaneliDetail />} />
        <Route path="/" element={<BlogSection />} />
        <Route path="/news/:id" element={<NewsDynamic />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/kvkk-policy" element={<KvkkPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <SocialSidebar />
      <ScrollToTopButton />
      <CookieConsent />
      <Footer />
    </>
  );
};

function App() {
  return (
    <div className="App">
      <Router>
        <AppContent />
      </Router>
    </div>
  );
}

export default App;
