import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactPage.css';
import Breadcrumb from './Breadcrumb';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next'; // i18n çeviri kancası

const ContactPage = () => {
  const { t } = useTranslation(); // i18n kullanımı
  const [isMapVisible, setIsMapVisible] = useState(false); // Haritanın görünür olup olmadığını takip etmek için state
  const mapContainerRef = useRef(null); // Harita div'ini takip etmek için ref
  const [showPopup, setShowPopup] = useState(false);

  const [formData, setFormData] = useState({
    host: `${process.env.REACT_APP_MAIL_SEND_HOST_DATA}`,
    fullName: "",
    email: "",
    subject: "",
    message: "",
});

const [responseMessage, setResponseMessage] = useState("");
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
};
const [captchaValue, setCaptchaValue] = useState(null);

const onCaptchaChange = (value) => {
  setCaptchaValue(value);
};
const handleSubmit = async (e) => {
  e.preventDefault();

  if (!captchaValue) {
    setResponseMessage("Please complete the CAPTCHA.");
    setShowPopup(true);
    return;
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_MAIL_HOST}:${process.env.REACT_APP_MAIL_PORT}/api/send`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, captcha: captchaValue }),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.text();
    setResponseMessage(data);
    setShowPopup(true);
  } catch (error) {
    setResponseMessage("There was a problem with the form submission.");
    setShowPopup(true);
    console.error("Error:", error);
  }
};

  // IntersectionObserver ile harita görünür hale gelince iframe yüklenir
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsMapVisible(true);
          observer.disconnect(); // Harita bir kez yüklendikten sonra observer'ı durdur
        }
      },
      {
        root: null, // Varsayılan olarak tarayıcı görünüm alanını kullan
        threshold: 0.1, // %10'u görünür olduğunda çalışır
      }
    );

    if (mapContainerRef.current) {
      observer.observe(mapContainerRef.current);
    }

    return () => {
      if (mapContainerRef.current) {
        observer.unobserve(mapContainerRef.current);
      }
    };
  }, []);

  return (
    <div className="container mt-5 contact-page">
      <Breadcrumb />

      <div className="row">
        <div className="col-lg-7 mb-4">
          <div className="contact-card shadow-sm border-0">
            <div className="contact-card-body">
              <h5 className="contact-card-title mb-4">{t('contactPage.contactInformation')}</h5>
              <table className="table contact-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">
                      <i className="fas fa-map-marker-alt text-danger me-2"></i>
                      {t('contactPage.address')}:
                    </td>
                    <td>{t('contactPage.addressDetail')}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">
                      <i className="fas fa-phone-alt text-danger me-2"></i>
                      {t('contactPage.phone')}:
                    </td>
                    <td>{t('contactPage.phoneDetail')}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">
                      <i className="fas fa-envelope text-danger me-2"></i>
                      {t('contactPage.email')}:
                    </td>
                    <td>{t('contactPage.emailDetail')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
          {/* Harita Konteyneri */}
          <div className="map-container mt-4" ref={mapContainerRef}>
            {isMapVisible && (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d265.92317480870844!2d29.022176075189996!3d41.056372356587964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa3404941560d%3A0xe2e0633123b0f4d5!2zSFNUIEhPTETEsE5HIEEuxZ4u!5e0!3m2!1sen!2str!4v1724422287719!5m2!1sen!2str"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps"
              ></iframe>
            )}
          </div>
        </div>

        <div className="col-lg-5">
          <p className="mb-4 text-start text-muted">{t('contactPage.contactFormDescription')}</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label text-muted text-start">
                {t('contactPage.fullName')}
              </label>
              <input type="text" onchance={handleChange} className="form-control" id="fullName" placeholder={t('contactPage.fullName')} />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label text-muted text-start">
                {t('contactPage.emailAddress')}
              </label>
              <input type="email" onchance={handleChange} className="form-control" id="email" placeholder={t('contactPage.emailAddress')} />
            </div>
            <div className="mb-3">
              <label htmlFor="subject" className="form-label text-muted text-start">
                {t('contactPage.subject')}
              </label>
              <input type="text" onchance={handleChange} className="form-control" id="subject" placeholder={t('contactPage.subject')} />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label text-muted text-start">
                {t('contactPage.message')}
              </label>
              <textarea className="form-control" onchance={handleChange} id="message" rows="5" placeholder={t('contactPage.message')}></textarea>
            </div>
            <div className="form-check mb-3">
              <input type="checkbox"  className="form-check-input" id="consent" />
              <label className="form-check-label text-muted text-start" htmlFor="consent">
                {t('contactPage.consentText')}
              </label>
            </div>
            <ReCAPTCHA className=" w-100 mb-3"
                  sitekey="6LebXCcqAAAAAAM5YcEdxiikEnu--gQUZU1elqIL"
                  onChange={onCaptchaChange}
                />
            <button type="submit" className="btn btn-danger w-100 mb-5 btn-contact">
              {t('contactPage.submit')}
            </button>
          </form>
          {showPopup && (
                <div
                  style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    padding: "15px",
                    backgroundColor: responseMessage.includes("successfully") ? "green" : "red",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  {responseMessage.includes("successfully")
                    ? t('emailSuccess')
                    : t('emailError')}
                </div>
              )}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
